export interface IConfig {
    ZEUS_API_URL?: string;
    REACT_APP_API_URL?: string;
    REACT_APP_LOGIN_URL: string;
    REACT_APP_LOGOUT_URL?: string;
    REACT_APP_UPDATE_PASSWORD_URL?: string;
    REACT_APP_PROFILE_URL?: string;
    REACT_APP_USER_SETTINGS_URL?: string;
    REACT_APP_DEFAULT_LANGUAGE?: string;
    REACT_APP_HSB?: string;
    SHOP_HOST?: string;
    REACT_APP_CLOUD_API_BASE_URL?: string;
    REACT_APP_SPOOFED_COMPANY_ID?: number;
    REACT_APP_SENTRY_DSN?: string;
}

const windowEnv = (window as unknown as { env: IConfig }).env || {};

export const config: IConfig = {
    ZEUS_API_URL: windowEnv.ZEUS_API_URL || process.env.ZEUS_API_URL || "http://localhost:3400",
    REACT_APP_API_URL:
        windowEnv.REACT_APP_API_URL || process.env.REACT_APP_API_URL || "http://localhost:4100/api",
    REACT_APP_LOGIN_URL:
        windowEnv.REACT_APP_LOGIN_URL ||
        process.env.REACT_APP_LOGIN_URL ||
        "http://localhost:4100/login",
    REACT_APP_LOGOUT_URL:
        windowEnv.REACT_APP_LOGOUT_URL ||
        process.env.REACT_APP_LOGOUT_URL ||
        "http://localhost:4100/logout",
    REACT_APP_UPDATE_PASSWORD_URL:
        windowEnv.REACT_APP_UPDATE_PASSWORD_URL ||
        process.env.REACT_APP_UPDATE_PASSWORD_URL ||
        "http://localhost:4100/update_password",
    REACT_APP_PROFILE_URL:
        windowEnv.REACT_APP_PROFILE_URL ||
        process.env.REACT_APP_PROFILE_URL ||
        "http://localhost:4100/profile",
    REACT_APP_USER_SETTINGS_URL:
        windowEnv.REACT_APP_USER_SETTINGS_URL ||
        process.env.REACT_APP_USER_SETTINGS_URL ||
        "http://localhost:4100/user_settings",
    REACT_APP_DEFAULT_LANGUAGE:
        windowEnv.REACT_APP_DEFAULT_LANGUAGE || process.env.REACT_APP_DEFAULT_LANGUAGE,
    REACT_APP_HSB: windowEnv.REACT_APP_HSB || process.env.REACT_APP_HSB,
    SHOP_HOST: windowEnv.SHOP_HOST || process.env.SHOP_HOST || "shop.grabcad.com",
    REACT_APP_CLOUD_API_BASE_URL:
        windowEnv.REACT_APP_CLOUD_API_BASE_URL ||
        process.env.REACT_APP_CLOUD_API_BASE_URL ||
        "https://test-shared-print-api.grabcad.net",
    REACT_APP_SPOOFED_COMPANY_ID:
        windowEnv.REACT_APP_SPOOFED_COMPANY_ID || process.env.REACT_APP_SPOOFED_COMPANY_ID
            ? Number(process.env.REACT_APP_SPOOFED_COMPANY_ID)
            : undefined,
    REACT_APP_SENTRY_DSN: windowEnv.REACT_APP_SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN,
};
