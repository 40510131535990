import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { i18n } from "./i18n";
import * as Sentry from "@sentry/browser";
import { config } from "./config";

if (config.REACT_APP_SENTRY_DSN && config.REACT_APP_SENTRY_DSN !== "false") {
    Sentry.init({ dsn: config.REACT_APP_SENTRY_DSN });
}

ReactGA.initialize("UA-17245501-6");
ReactDOM.render(i18n(<App />), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
