import { useContext } from "react";
import { ApplicationContext } from "../../components/ApplicationProvider";
import styled, { Image, Tab } from "grabcad-ui-elements";
import { SdkStigCard } from "../SDK/SdkStigCard";
import Parser from "html-react-parser";
import AnalyzeIcon from "../../images/Analyze-logo.svg";
import Download from "../../images/Download.svg";
import { PostInstallationInfo } from "./PostInstallationInfo";

const ServerInstallerContainer = styled.div`
    margin-top: 40px;
`;

export const InstallingAnalyzeServerInfo = () => {
    const { t } = useContext(ApplicationContext);

    const grabcadAnalyzeServerUrl = `https://gc-analyze-installers.s3.amazonaws.com/latestrelease/GrabCADAnalyzeServerInstaller.exe`;
    const version = "";
    const lastModified = ``;
    const releaseNotesLink = ``;
    const vagrantInstallerLink = `https://developer.hashicorp.com/vagrant/install#windows`;
    const vmboxDownloadLink = `https://download.virtualbox.org/virtualbox/7.0.18/VirtualBox-7.0.18-162988-Win.exe`;
    const linuxInstallerUrl = `https://gc-analyze-installers.s3.amazonaws.com/latestrelease/GrabCADAnalyzeServerInstaller-bundle.tar.gz`;

    const overviewWindowsInstallerCard = () => (
        <>
            <h4>{t("configure_analyze.sub_field_subtitle")}</h4>

            <a
                href={vmboxDownloadLink}
                style={{ display: "flex", width: "fit-content", color: "#003393" }}
            >
                <Image src={Download} /> &nbsp; Oracle VM VirtualBox
            </a>
            <a
                href={vagrantInstallerLink}
                style={{ display: "flex", width: "fit-content", color: "#003393" }}
                target="_blank"
                rel="noreferrer"
            >
                <i className="external alternate icon"></i> &nbsp; Vagrant by HashiCorp
            </a>

            <SdkStigCard
                id={1}
                description={Parser(t("configure_analyze.analyze_server_installer_description"))}
                url={grabcadAnalyzeServerUrl}
                version={version}
                lastModified={lastModified}
                imageSrc={AnalyzeIcon}
                releaseNotesLink={releaseNotesLink}
                expired={false}
                showBorder={false}
                showBoxShadow={false}
                showVersionAndDetails={false}
            />
            <PostInstallationInfo system="Windows" />
        </>
    );

    const overviewLinuxInstallerCard = () => (
        <>
            <SdkStigCard
                id={1}
                description={Parser(
                    t("configure_analyze.analyze_server_linux_installer_description")
                )}
                url={linuxInstallerUrl}
                version={version}
                lastModified={lastModified}
                imageSrc={AnalyzeIcon}
                releaseNotesLink={releaseNotesLink}
                expired={false}
                showBorder={false}
                showBoxShadow={false}
                showVersionAndDetails={false}
            />
            <PostInstallationInfo system="Linux" />
        </>
    );

    return (
        <ServerInstallerContainer>
            <h3>{t("configure_analyze.installing_analyze_server")}</h3>
            <Tab
                menu={{ secondary: true, pointing: true, className: "qa-userTabs" }}
                panes={[
                    {
                        menuItem: t("configure_analyze.installing_on_windows"),
                        render: () => <>{overviewWindowsInstallerCard()}</>,
                    },
                    {
                        menuItem: t("configure_analyze.installing_on_linux"),
                        render: () => <>{overviewLinuxInstallerCard()}</>,
                        pane: {
                            style: { paddingTop: "40px" },
                        },
                    },
                ]}
            />
        </ServerInstallerContainer>
    );
};
