export const DebugTools: React.FC = (props) => {
    function throwReactError() {
        throw new Error("Simulated unexpected error in React");
    }

    return (
        <div>
            <h1>Debug Tools</h1>
            <div>
                <button onClick={throwReactError}>Throw React Sentry Error</button>
            </div>
        </div>
    );
};
