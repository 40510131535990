import { FocusEvent, ChangeEvent, FormEvent, useContext, useState } from "react";
import { IScreenProps } from "../Screen";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { Button, Form, FormField } from "grabcad-ui-elements";
import { Checkbox, Input, TextArea } from "semantic-ui-react";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { gql, useMutation } from "@apollo/client";
import { ExecutionResult } from "graphql";
import { Notifier } from "../../view/Notifier";

interface RequestResultProps {
    companyId: number;
    companyName: string;
}

const CREATE_COMPANY_FROM_WB = gql`
    mutation createCompanyFromWb(
        $name: String
        $accountIds: [Int!]!
        $copyAccountName: Boolean
        $notifyUsers: Boolean
    ) {
        createCompanyFromWb(
            name: $name
            accountIds: $accountIds
            copyAccountName: $copyAccountName
            notifyUsers: $notifyUsers
        ) {
            id
            name
        }
    }
`;

export const CreateCompanyFromWorkbench = (props: IScreenProps) => {
    const { t } = useContext(ApplicationContext);
    const [useWorkbenchCompanyName, setUseWorkbenchCompanyName] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [company, setCompany] = useState<{ id: number; name: string }>();

    const [mutation, { loading: loading }] = useMutation(CREATE_COMPANY_FROM_WB, {
        update: (_cache, { data: { createCompanyFromWb } }: ExecutionResult<any>) => {
            setCompany({
                id: createCompanyFromWb.id,
                name: createCompanyFromWb.name,
            });
            setSubmitClicked(false);
            Notifier.success(t("company.successfully_created"));
        },
        onError: (error) => {
            Notifier.error(error);
            setSubmitClicked(false);
        },
    });

    function resetErrorClass(target: HTMLFormElement) {
        target.closest(".field")!.classList.remove("error");
    }

    return (
        <>
            <Breadcrumbs sections={[{ label: "Company from Workbench" }]} />
            <Form
                noValidate
                style={{ maxWidth: 550, paddingBottom: 25 }}
                onSubmit={(e: FormEvent) => {
                    e.preventDefault();

                    if (loading) {
                        return;
                    }

                    const target = e.target as HTMLFormElement;
                    const isValid = target.checkValidity();

                    if (!isValid) {
                        const invalidFields = target.querySelectorAll(":invalid");
                        invalidFields.forEach((f) => {
                            f.closest(".field")!.classList.add("error");
                        });
                        return;
                    }

                    setSubmitClicked(true);
                    void mutation({
                        variables: {
                            name: target.companyName.value,
                            accountIds: target.accountIds.value.split(",").map((id: string) => +id),
                            copyAccountName: target.copyAccountName.checked,
                            notifyUsers: target.notifyUsers.checked,
                        },
                    });
                }}
            >
                <div style={{ marginBottom: 25 }}>
                    <FormField id="qa-wb-input-account-ids">
                        <label>
                            Enter Workbench account numbers
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </label>
                        <TextArea
                            placeholder="Comma separated Workbench accounts IDs"
                            name="accountIds"
                            required
                            onChange={(e: ChangeEvent) => {
                                resetErrorClass(e.target as HTMLFormElement);
                            }}
                            onBlur={(e: FocusEvent) => {
                                const target = e.target as HTMLTextAreaElement;
                                const parts = target.value.split(",");
                                const isValid = parts.every(
                                    (id) => id !== "" && !Number.isNaN(+id)
                                );

                                target.setCustomValidity(isValid ? "" : "Invalid Workbench ID(s)");
                            }}
                        />
                    </FormField>
                    <Checkbox
                        label="Copy first Workbench Account name"
                        name="copyAccountName"
                        onClick={() => setUseWorkbenchCompanyName(!useWorkbenchCompanyName)}
                    />
                </div>
                <div style={{ marginBottom: 25 }}>
                    <FormField disabled={useWorkbenchCompanyName} id="qa-wb-input-company-name">
                        <label>
                            Enter Company name
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </label>
                        <Input
                            name="companyName"
                            required={!useWorkbenchCompanyName}
                            onChange={(e: ChangeEvent) =>
                                resetErrorClass(e.target as HTMLFormElement)
                            }
                        />
                    </FormField>
                </div>
                <FormField key="buttons">
                    <Checkbox
                        label="Notify company users after company is created"
                        name="notifyUsers"
                        defaultChecked
                    />
                    <Button
                        id="qa-wb-submit"
                        type="submit"
                        className="primary right floated"
                        disabled={submitClicked}
                    >
                        {t("forms.submit")}
                    </Button>
                </FormField>
            </Form>
            {loading ? (
                <div className="ui loader active" />
            ) : company ? (
                <RequestResult companyId={company.id} companyName={company.name} />
            ) : (
                ""
            )}
        </>
    );
};

export const RequestResult = (props: RequestResultProps) => {
    return (
        <div>
            <h3>Result:</h3>
            <a href={`/company/${props.companyId}`}>{props.companyName}</a>
        </div>
    );
};
